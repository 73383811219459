import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  TitleBox,
  TextFrame,
  PageNameBox,
  OctopusBlueFrame,
} from "@minware/ui-minware";

export const AboutPageTemplate = ({
  header,
  octopus,
  pageName,
  title,
  topHeading,
  topSubHeading,
  content,
}) => {
  const headerWithCanon = {
    ...header,
    canonicalUrl: '/about',
  }
  content = content || {};
  octopus = octopus || {};
  return (
    <Layout>
      <HeaderMeta header={headerWithCanon}/>

      <ResponsiveMaxWidthFrame maxWidth="520px">
        <SectionGroup>
          <PageNameBox>{pageName}</PageNameBox>
          <TitleBox>
            {title}
          </TitleBox>
        </SectionGroup>
        <TextFrame
          heading={topHeading}
          subHeadline={topSubHeading}
          outlineBox={true}
        />
      </ResponsiveMaxWidthFrame>

      <TextFrame
          heading={content.heading}
          subHeadline={content.subHeading}
          twoColDesktop={true}
        />

      <TextFrame
          heading="We eat our own dog food."
          subHeadline="We use minware ourselves to build software with extreme efficiency. Everything you see here was created with a small team with no outside capital. Everyone at our company has complete visibility into the software development process, which has fostered professional growth and helped us maintain high standards while building minware."
          twoColDesktop={true}
        />

      <TextFrame
          heading="We embrace bootstrapping and remote work."
          subHeadline="Our founders came from Collage.com, a bootstrapped all-remote company that grew to $86m revenue with a team of 15 engineers before selling to private equity in 2021. We know what it takes to pull this off, and created minware to help you achieve the same level of efficiency."
          ctaText={content.ctaText}
          ctaLink={content.ctaLink}
          twoColDesktop={true}
        />

    </Layout>
  )
}

AboutPageTemplate.propTypes = {
  pretitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { main, index } = data;

  const post = main.frontmatter;

  return (
    <AboutPageTemplate
      header={main.frontmatter.header}
      octopus={index.frontmatter.octopus}
      {...post}
    />
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    index: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      ...OctopusFragment
    }
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      frontmatter {
        pageName,
        title,
        topHeading,
        topSubHeading,
        content {
          heading,
          subHeading,
          ctaText,
          ctaLink
        }
      }
    }
  }
`
